import { Flex, Box, AvatarGroup, Avatar } from "@chakra-ui/react";
import { GradientText } from "../shared/generic";

export const TrustedUsers = ({ testimonials }) => {
  const textSuccessful = {
    from: "#EC538A 61.12%",
    to: "#842A62 139.29%",
    fontSize: { base: "0.95em", md: "1em" },
    degree: "200deg",
    display: "inline",
    mobileBg: "#EC538A",
  };

  if (testimonials?.length) {
    return (
      <Flex gap={4} align="center">
        <AvatarGroup size="md">
          <Avatar
            border="none"
            src={`/images/testimonials/${testimonials[0]?.image ?? "default"}.jpg`}
          />
          <Avatar
            border="none"
            src={`/images/testimonials/${testimonials[1]?.image ?? "default"}.jpg`}
          />
          <Avatar
            border="none"
            src={`/images/testimonials/${testimonials[2]?.image ?? "default"}.jpg`}
          />
        </AvatarGroup>

        <Box fontSize="md">
          Trusted by{" "}
          <GradientText {...textSuccessful} fontWeight="700" display="inline-block">
            63,427
          </GradientText>
          <br />
          students & parents
        </Box>
      </Flex>
    );
  }
};
